if (process.env.NODE_ENV === "development") {
    const CONSTANTS = {
        imageUrl: "https://console.staging.truabilities.com/assets/dist/img/",
        imageUrlFrontEnd: "https://console.staging.truabilities.com/frontend_assets/",
        imageUrlS3: "https://truabilities-dev.s3.us-west-1.amazonaws.com/portal/User%20Portal/",
        iconUrl: "https://truabilities-dev.s3.us-west-1.amazonaws.com/application/assets/images/v2/",
        s3RootUrl: 'https://truabilities-dev.s3.us-west-1.amazonaws.com/',
        // apiKey: "wbkjmduvf37h84zcn6pa0tsexiq29y1r",
        scriptTag: "<script type='text/javascript' async src='https://app.dev.truabilities.com/release/truabilities.js?widgetkey='></script>",
        // cryptoSecret: "puiydjlc1x4f8het5ozkwbrgn9qsv6a3",
        apiKey: "zcE1iEWRBb5YjVnUDbW3qhuOF3eIQ5qA",
        jwtsecret: "r0Q3adF2ugPbCh53dykigd6Bok1XDQUP",
        cryptoSecret: "Fb3Tuk2EW1mLcJqh0c3rVzDN2xxX9XyY",
        frontendUrl: "https://truabdev.wpengine.com/",
        stripeKey: "pk_test_DpGQGT4LBWaCEHIifkgmsqeK00oGwweNZa",
        logoUrl : "https://truabilities-dev.s3.us-west-1.amazonaws.com/portal/User%20Portal/logo-white.png?AWSAccessKeyId=AKIAZJ4VKLIYQDJKT4WU&Expires=1966098152&Signature=bwrORPnXzq0LHy6VMwuIUV8R%2FQg%3D"
    };

    module.exports = CONSTANTS;
} else if (process.env.NODE_ENV === "production") {
    const CONSTANTS = {
        imageUrl: "https://truabilities.ntooitive.com/assets/dist/img/",
        imageUrlFrontEnd: "https://truabilities.ntooitive.com/frontend_assets/",
        iconUrl: "https://truabilities-prod.s3.us-west-1.amazonaws.com/application/assets/images/v2/",
        s3RootUrl: 'https://truabilities-prod.s3.us-west-1.amazonaws.com/',
        apiKey: "wbkjmduvf37h84zcn6pa0tsexiq29y1r",
        scriptTag: "<script type='text/javascript' async src='https://truabilitiesapp.ntooitive.com/release/truabilities.js?widgetkey=' crossorigin='anonymous'></script>",
        cryptoSecret: "puiydjlc1x4f8het5ozkwbrgn9qsv6a3",
        frontendUrl: "https://truabilities.com/",
        stripeKey: "pk_test_DpGQGT4LBWaCEHIifkgmsqeK00oGwweNZa",
        logoUrl : "https://truabilities-dev.s3.us-west-1.amazonaws.com/portal/User%20Portal/logo-white.png?AWSAccessKeyId=AKIAZJ4VKLIYQDJKT4WU&Expires=1966098152&Signature=bwrORPnXzq0LHy6VMwuIUV8R%2FQg%3D"
    };

    module.exports = CONSTANTS;
}